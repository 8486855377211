import React, { useReducer, useEffect } from 'react'
import UserContext from './UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import UserReducer from './UserReducer'
import { SET_USER_ROLE } from '../../types/user'

const UserState = (props) => {
  //Get user information from Auth0
  const { user, getIdTokenClaims } = useAuth0()
  const { name, email, picture } = user

  //Load user roles from access token
  useEffect(() => {
    const namespace = 'https://lncpaymentsapi/roles'
    const claimsCall = async () => {
      const claims = await getIdTokenClaims()

      const roles = claims[namespace]

      //setRole(roles[0])
      dispatch({
        type: SET_USER_ROLE,
        payload: roles[0],
      })
    }

    claimsCall()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Initial state
  const initialState = {
    userData: {
      name: name,
      email: email,
      imageUrl: picture,
    },
    role: 'No Role',
  }

  //Make use of reducer
  const [state, dispatch] = useReducer(UserReducer, initialState)

  return (
    <UserContext.Provider
      value={{ userData: state.userData, role: state.role }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserState
