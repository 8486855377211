import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Loading from '../shared/Loading'
import TableLoading from '../shared/TableLoading'
import NodeAlias from './NodeAlias'

const NodeCard = () => {
  //Local state
  const [nodeInfo, setNodeInfo] = useState({
    nodeId: '',
    shortId: '',
    nodeHost: '',
    shortNodeHost: '',
    blockHeight: 0,
    isSinced: false,
    status: 'loading',
  })

  const [nodeOnchainBalance, setNodeOnChainBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    reserved: 0,
  })

  const [nodeOffchainBalance, setNodeOffchainBalance] = useState({
    local: 0,
    remote: 0,
    opening: 0,
    closing: 0,
  })

  const [channels, setChannels] = useState([])

  //Api config
  const { getAccessTokenSilently } = useAuth0()
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const scopes = 'manage:system'
  const apiUrl = process.env.REACT_APP_API

  //Load Node Info
  useEffect(() => {
    const getNodeInfo = async () => {
      const token = await getAccessTokenSilently({
        audience: audience,
        scope: scopes,
      })

      const nodeInfo = await axios.get(apiUrl + '/node', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      var totalHostLength = nodeInfo.data.extendedInfo.host.length
      var totalIdLength = nodeInfo.data.extendedInfo.id.length

      setNodeInfo({
        nodeId: nodeInfo.data.extendedInfo.id,
        shortId:
          nodeInfo.data.extendedInfo.id.substring(0, 7) +
          '...' +
          nodeInfo.data.extendedInfo.id.substring(
            totalIdLength - 7,
            totalIdLength
          ),
        nodeHost: nodeInfo.data.extendedInfo.host,
        shortNodeHost:
          nodeInfo.data.extendedInfo.host.substring(0, 5) +
          '...' +
          nodeInfo.data.extendedInfo.host.substring(
            totalHostLength - 15,
            totalHostLength
          ),
        blockHeight: nodeInfo.data.extendedInfo.blockHeight,
        isSinced: nodeInfo.data.extendedInfo.isSinced,
        status: 'loaded',
      })

      setNodeOnChainBalance({
        confirmed: nodeInfo.data.onchainBalance.confirmed,
        unconfirmed: nodeInfo.data.onchainBalance.unconfirmed,
        reserved: nodeInfo.data.onchainBalance.reserved,
      })

      setNodeOffchainBalance({
        local: nodeInfo.data.offchainBalance.local,
        remote: nodeInfo.data.offchainBalance.remote,
        opening: nodeInfo.data.offchainBalance.opening,
        closing: nodeInfo.data.offchainBalance.closing,
      })

      setChannels(nodeInfo.data.channels)
    }

    getNodeInfo()
  }, [])

  return (
    <div className='px-7 pb-2 pt-2 bg-white rounded-5xl'>
      {nodeInfo.status === 'loading' ? (
        <Loading />
      ) : (
        <>
          <div className='flex flex-wrap p-6 pb-4 -m-5 border-b border-coolGray-100'>
            <div className='w-full p-2'>
              <div className='flex justify-center -m-2'>
                <div className='p-2'>
                  <h1 className='text-4xl font-medium text-indigo-900'>
                    Información del Nodo
                  </h1>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-auto p-2'>
              <div className='flex flex-wrap items-center -m-2'>
                <div className='w-auto p-2'>
                  <h2 className='text-md font-medium text-coolGray-900'>
                    Dirección : {nodeInfo.shortNodeHost}
                  </h2>
                  <h3 className='text-sm font-medium text-coolGray-400'>
                    ID: {nodeInfo.shortId}
                  </h3>
                  <h3 className='text-sm font-medium text-coolGray-400'>
                    Blockheight: {nodeInfo.blockHeight}
                  </h3>
                  <h3 className='text-sm font-medium text-coolGray-400'>
                    Sincronizado:
                    {nodeInfo.isSinced ? (
                      <span className='py-1 px-2 text-sm text-green-900 font-medium bg-green-200 rounded-full'>
                        True
                      </span>
                    ) : (
                      <span className='py-1 px-2 text-sm text-green-900 font-medium bg-red-200 rounded-full'>
                        False
                      </span>
                    )}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-wrap px-2 py-6 m-2 border-b border-coolGray-100'>
            <div className='w-full p-2'>
              <div className='flex justify-center -m-2'>
                <div className='p-2'>
                  <h1 className='text-4xl font-medium text-indigo-900'>
                    Balances
                  </h1>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-1/2 p-2'>
              <div className='text-center'>
                <h1 className='text-lg font-medium text-coolGray-900'>
                  Onchain Balance
                </h1>
                <p className='mb-1 text-xs text-coolGray-900 font-semibold'>
                  24
                </p>
                <p className='text-xs text-coolGray-400 font-medium'>Images</p>
              </div>
            </div>
            <div className='w-full lg:w-1/2 p-2'>
              <div className='text-center'>
                <h1 className='text-lg font-medium text-coolGray-900'>
                  Offchain Balance
                </h1>
                <div className='flex flex-wrap -m-4 pb-16 pt-5'>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='pt-1 pb-4 px-6 border rounded'>
                      <h4 className='text-md text-gray-500'>Local</h4>
                      <div className='flex items-center'>
                        <span className='mr-2 text-3xl font-bold'>
                          {nodeOffchainBalance.local} BTC
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='pt-1 pb-4 px-6 border rounded'>
                      <h4 className='text-md text-gray-500'>Remote</h4>
                      <div className='flex items-center'>
                        <span className='mr-2 text-3xl font-bold'>
                          {nodeOffchainBalance.remote} BTC
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='pt-1 pb-4 px-6 border rounded'>
                      <h4 className='text-md text-gray-500'>Opening</h4>
                      <div className='flex items-center'>
                        <span className='mr-2 text-3xl font-bold'>
                          {nodeOffchainBalance.opening} BTC
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-4'>
                    <div className='pt-1 pb-4 px-6 border rounded'>
                      <h4 className='text-md text-gray-500'>Closing</h4>
                      <div className='flex items-center'>
                        <span className='mr-2 text-3xl font-bold'>
                          {nodeOffchainBalance.closing} BTC
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Channels information*/}
          <div className='container px-4 mx-auto'>
            <div className='px-8 pb-16 bg-white rounded-5xl'>
              <div className='w-full p-2'>
                <div className='flex justify-center -m-2'>
                  <div className='p-2'>
                    <h1 className='text-4xl font-medium text-indigo-900'>
                      Canales
                    </h1>
                  </div>
                </div>
              </div>
              <div className='overflow-x-auto pt-6'>
                <div className='inline-block w-full min-w-max overflow-hidden'>
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className='pb-8 text-sm text-body text-left text-opacity-40 font-heading font-semibold uppercase'>
                          Canal
                        </th>
                        <th className='pb-8 text-sm text-body text-left text-opacity-40 font-heading font-semibold uppercase'>
                          Capacidad
                        </th>
                        <th className='pb-8 text-sm text-body text-center text-opacity-40 font-heading font-semibold uppercase'>
                          Balance Local
                        </th>
                        <th className='pb-8 pr-10 text-sm text-body text-right text-opacity-40 font-heading font-semibold uppercase'>
                          Balance Remoto
                        </th>
                        <th className='pb-8 pr-10 text-sm text-body text-right text-opacity-40 font-heading font-semibold uppercase'>
                          Info
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {nodeInfo.status === 'loading'
                        ? null
                        : channels.map((chann) => (
                            <tr key={chann.channelId}>
                              <td className='p-0 pb-3'>
                                <div className='flex items-center pr-4 h-20 bg-blueGray-50'>
                                  <div className='flex items-center'>
                                    <div className='flex-shrink-0'>
                                      <h4 className='font-heading font-medium leading-4 text-indigo-500 pb-2'>
                                        <NodeAlias nodeId={chann.remoteNode} />
                                      </h4>
                                      <h4 className='font-heading font-medium leading-4'>
                                        {chann.channelId}
                                      </h4>
                                      <span className='text-sm text-darkBlueGray-400 pt-2 leading-3'>
                                        {chann.remoteNode}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className='p-0'>
                                <div className='flex items-center h-14 text-center bg-blueGray-50'>
                                  <span className='font-heading text-darkBlueGray-400'>
                                    {chann.capacity} BTC
                                  </span>
                                </div>
                              </td>
                              <td className='p-0'>
                                <div className='flex items-center h-14 text-center bg-blueGray-50'>
                                  <span className='font-heading text-darkBlueGray-400'>
                                    {chann.localBalance} BTC
                                  </span>
                                </div>
                              </td>
                              <td className='p-0'>
                                <div className='flex items-center h-14 text-center bg-blueGray-50'>
                                  <span className='font-heading text-darkBlueGray-400'>
                                    {chann.remoteBalance} BTC
                                  </span>
                                </div>
                              </td>
                              <td className='p-0'>
                                <div className='flex items-center h-14 text-center bg-blueGray-50'>
                                  {chann.active ? (
                                    <span className='py-1 px-2 text-sm text-green-900 font-medium bg-green-200 rounded-full'>
                                      Active
                                    </span>
                                  ) : (
                                    <span className='py-1 px-2 text-sm text-green-900 font-medium bg-red-200 rounded-full'>
                                      Inactive
                                    </span>
                                  )}
                                  {chann.private ? (
                                    <span className='py-1 px-2 text-sm text-green-900 font-medium bg-red-200 rounded-full'>
                                      Private
                                    </span>
                                  ) : (
                                    <span className='py-1 px-2 text-sm text-green-900 font-medium bg-green-200 rounded-full'>
                                      Public
                                    </span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default NodeCard
