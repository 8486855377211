import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import TableLoading from '../../shared/TableLoading'
import axios from 'axios'

const AdminApps = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [apps, setApps] = useState({
    totalApps: 0,
    apps: [],
    status: 'loading',
  })

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const scopes = 'manage:system'

  useEffect(() => {
    const getApps = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: audience,
          scope: scopes,
        })
        const appsIndb = await axios.get(
          process.env.REACT_APP_API + '/applications',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        setApps({
          totalApps: appsIndb.data.length,
          apps: appsIndb.data,
          status: 'loaded',
        })
      } catch (error) {}
    }
    getApps()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section className='py-8 bg-blueGray-50'>
        <div className='container px-4 mx-auto'>
          <div className='p-7 pb-12 pt-12 bg-white rounded-5xl'>
            <div className='flex h-15 justify-center md:justify-start -m-4'>
              <h2 className='pl-10 text-3xl font-heading font-medium text-indigo-700'>
                Aplicaciones{' '}
                <span className='hidden md:inline'>Registradas</span>
              </h2>
              <span className='my-2 ml-3 py-1 px-2 text-sm text-indigo-700 font-heading font-medium bg-indigo-200 rounded-xl'>
                {apps.status === 'loading' ? (
                  <div className='animate-pulse'>-</div>
                ) : (
                  apps.totalApps
                )}
              </span>
            </div>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full overflow-hidden'>
                <table className='mb-10 mt-5 table-auto w-full'>
                  <thead>
                    <tr>
                      <th className='pl-10 xl:pl-24 h-20 bg-white text-left'>
                        <span className='block text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'>
                          Usuario
                        </span>
                      </th>
                      <th className='p-5 h-20 bg-white'>
                        <span className='block text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'>
                          Nombre
                        </span>
                      </th>
                      <th className='p-5 h-20 bg-white'>
                        <span className='block text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'>
                          Balance
                        </span>
                      </th>
                      <th className='p-5 h-20 bg-white'>
                        <span className='block text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'>
                          Invoices
                        </span>
                      </th>
                      <th className='p-5 h-20 bg-white'>
                        <span className='block text-sm text-body text-opacity-40 font-heading font-semibold uppercase min-w-max'>
                          Estado
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {apps.status === 'loading' ? (
                      <tr>
                        <td colSpan='5'>
                          <TableLoading />
                        </td>
                      </tr>
                    ) : (
                      apps.apps.map((app) => (
                        <tr key={app.id}>
                          <td className='p-0'>
                            <div className='flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl'>
                              <span className='text-lg font-heading font-medium'>
                                {app.user}
                              </span>
                            </div>
                          </td>
                          <td className='p-0'>
                            <div className='flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100'>
                              <span className='text-lg text-darkBlueGray-400 font-heading'>
                                {app.name}
                              </span>
                            </div>
                          </td>
                          <td className='p-0'>
                            <div className='flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100'>
                              <span className='text-lg text-darkBlueGray-400 font-heading'>
                                {app.balance} BTC
                              </span>
                            </div>
                          </td>
                          <td className='p-0'>
                            <div className='flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100'>
                              <span className='text-lg text-darkBlueGray-400 font-heading'>
                                {app.invoices}
                              </span>
                            </div>
                          </td>
                          <td className='p-0'>
                            <div className='flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100 rounded-tr-5xl rounded-br-5xl'>
                              <span className='py-1 px-3 text-sm text-green-700 font-heading font-medium bg-green-200 rounded-full'>
                                {app.status}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pl-10 xl:pl-24 max-w-max'>
              <button
                disabled={apps.status === 'loading' ? true : false}
                className='block py-4 px-7 w-full leading-3 text-white font-semibold tracking-tighter font-heading text-center bg-indigo-500 hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 rounded-xl'
              >
                Show more
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdminApps
