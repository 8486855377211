import { HIGHLIGHT_NAVBAR_ITEM, GENERATE_NAV_LINKS } from '../../types/navbar'

const NavBarReducer = (state, action) => {
  switch (action.type) {
    case HIGHLIGHT_NAVBAR_ITEM:
      return {
        ...state,
        navigation: [
          ...state.navigation.map((item) =>
            item.name === action.payload ? { ...item, current: true } : item
          ),
        ],
      }
    default:
      return state
  }
}

export default NavBarReducer
