import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory'
import Home from './pages/Home'
import LogOut from './pages/log-out'
import DashboardPage from './pages/DashboardPage'
import UsersPage from './pages/UsersPage'
import AppsPage from './pages/AppsPage'
import NotFoundPage from './pages/NotFoundPage'
import InvoicesInfo from './components/invoices/InvoicesInfo'
import NodePage from './pages/NodePage'

function App() {
  return (
    <Router>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/logout' element={<LogOut />} />
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/usuarios' element={<UsersPage />} />
          <Route path='/aplicaciones' element={<AppsPage />} />
          <Route path='/facturas' element={<InvoicesInfo />} />
          <Route path='/nodo' element={<NodePage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Auth0ProviderWithHistory>
    </Router>
  )
}

export default App
