import MainTemplate from '../templates/MainTemplate'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../components/shared/Loading'
import NavBarState from '../context/navbar/NavBarState'
import UserState from '../context/user/UserState'

const UsersPage = () => {
  return (
    <UserState>
      <NavBarState>
        <MainTemplate element='Usuarios'>
          <main>
            <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
              {/* Replace with your content */}
              <div className='px-4 py-6 sm:px-0'>
                <div className='border-4 border-dashed border-gray-200 rounded-lg h-96'>
                  <h1>Datos de Usuarios</h1>
                </div>
              </div>
              {/* /End replace */}
            </div>
          </main>
        </MainTemplate>
      </NavBarState>
    </UserState>
  )
}

export default withAuthenticationRequired(UsersPage, {
  onRedirecting: () => <Loading />,
})
