import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const NodeAlias = ({ nodeId }) => {
  const [nodeData, setNodeData] = useState({
    alias: '',
    loaded: false,
  })

  //Api config
  const { getAccessTokenSilently } = useAuth0()
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const scopes = 'manage:system'
  const apiUrl = process.env.REACT_APP_API

  useEffect(() => {
    const getNodeInfo = async () => {
      const token = await getAccessTokenSilently({
        audience: audience,
        scope: scopes,
      })

      const nodeInfo = await axios.get(apiUrl + '/node/get-alias/' + nodeId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setNodeData({
        alias: nodeInfo.data.alias,
        loaded: true,
      })
    }

    getNodeInfo()
  }, [nodeId])

  return nodeData.loaded ? nodeData.alias : ''
}

export default NodeAlias
