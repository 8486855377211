import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import {
  CashIcon,
  ChartBarIcon,
  CreditCardIcon,
  UserIcon,
} from '@heroicons/react/outline'
import StatsLoadingSpinner from '../../shared/StatsLoadingSpinner'

const Stats = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalApps: 0,
    totalInvoices: 0,
    totalWithdrawals: 0,
    status: 'loading',
  })

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const scopes = 'manage:system'
  const apiUrl = process.env.REACT_APP_API

  //Remove this from here
  useEffect(() => {
    const getStats = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: audience,
          scope: scopes,
        })
        const stats = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setStats({
          totalUsers: stats.data.users,
          totalApps: stats.data.apps,
          totalInvoices: stats.data.invoices,
          totalWithdrawals: stats.data.withdrawals,
          status: 'loaded',
        })
      } catch (error) {
        //TODO: Manage errors properly
        if (error.response) {
          console.log('Error response', error.response.data)
          console.log('Error response', error.response.status)
          console.log('Error response', error.response.headers)
        } else if (error.request) {
          console.log('Error request', error.request)
        } else {
          console.log('Error', error.message)
        }

        setStats({
          totalUsers: 0,
          totalApps: 0,
          totalInvoices: 0,
          totalWithdrawals: 0,
          status: 'error',
        })
      }
    }

    getStats()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='py-8'>
      <div className='container px-4 mx-auto'>
        <div className='flex flex-wrap -m-4'>
          <div className='w-full md:w-1/2 lg:w-1/4 p-4'>
            <div className='p-6 rounded bg-white'>
              <div className='flex mb-2'>
                <span className='inline-block mr-2'>
                  <UserIcon className='h-8 w-8 text-indigo-300' />
                </span>
                <h3 className='text-2xl text-gray-600'>Usuarios</h3>
              </div>
              <h2 className='mb-2 mt-4 text-3xl font-bold text-indigo-600'>
                {stats.status === 'loading' ? (
                  <StatsLoadingSpinner />
                ) : (
                  stats.totalUsers
                )}
              </h2>
            </div>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/4 p-4'>
            <div className='p-6 rounded bg-white'>
              <div className='flex mb-2'>
                <span className='inline-block mr-2'>
                  <ChartBarIcon className='h-8 w-8 text-cyan-600' />
                </span>
                <h3 className='text-2xl text-gray-600'>Aplicaciones</h3>
              </div>
              <h2 className='mb-2 mt-4 text-3xl font-bold'>
                {stats.status === 'loading' ? (
                  <StatsLoadingSpinner />
                ) : (
                  stats.totalApps
                )}
              </h2>
            </div>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/4 p-4'>
            <div className='p-6 rounded bg-white'>
              <div className='flex mb-2'>
                <span className='inline-block mr-2'>
                  <CashIcon className='h-8 w-8 text-cyan-600' />
                </span>
                <h3 className='text-2xl text-gray-600'>Facturas</h3>
              </div>
              <h2 className='mb-2 mt-4 text-3xl font-bold'>
                {stats.status === 'loading' ? (
                  <StatsLoadingSpinner />
                ) : (
                  stats.totalInvoices
                )}
              </h2>
            </div>
          </div>
          <div className='w-full md:w-1/2 lg:w-1/4 p-4'>
            <div className='p-6 rounded bg-white'>
              <div className='flex mb-2'>
                <span className='inline-block mr-2'>
                  <CreditCardIcon className='h-8 w-8 text-cyan-600' />
                </span>
                <h3 className='text-2xl text-gray-600'>Extracciones</h3>
              </div>
              <h2 className='mb-2 mt-4 text-3xl font-bold'>
                {stats.status === 'loading' ? (
                  <StatsLoadingSpinner />
                ) : (
                  stats.totalWithdrawals
                )}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Stats
