import React, { useContext, useReducer } from 'react'
import NabVarContext from './NabVarContext'
import NavBarReducer from './NavBarReducer'
import { HIGHLIGHT_NAVBAR_ITEM } from '../../types/navbar'
import UserContext from '../user/UserContext'

const NavBarState = (props) => {
  //Read information from UserContext
  const userState = useContext(UserContext)
  const { userData } = userState
  const { name, email, imageUrl } = userData

  const initialState = {
    userData: {
      name: name,
      email: email,
      imageUrl: imageUrl,
    },
    navigation: [
      {
        name: 'Dashboard',
        href: '/dashboard',
        current: false,
        roles: ['Administrator', 'User'],
      },
      {
        name: 'Usuarios',
        href: '/usuarios',
        current: false,
        roles: ['Administrator'],
      },
      {
        name: 'Aplicaciones',
        href: '/aplicaciones',
        current: false,
        roles: ['Administrator', 'User'],
      },
      {
        name: 'Facturas',
        href: '/facturas',
        current: false,
        roles: ['Administrator', 'User'],
      },
      {
        name: 'Extracciones',
        href: '/extracciones',
        current: false,
        roles: ['Administrator', 'User'],
      },
      {
        name: 'Nodo',
        href: '/nodo',
        current: false,
        roles: ['Administrator'],
      },
    ],
  }

  const [state, dispatch] = useReducer(NavBarReducer, initialState)

  const navigateToItem = (element) => {
    dispatch({
      type: HIGHLIGHT_NAVBAR_ITEM,
      payload: element,
    })
  }

  const userNavigation = [
    { name: 'Your Profile', href: '/profile' },
    { name: 'Settings', href: '/settings' },
    { name: 'Logout', href: 'logout' },
  ]

  return (
    <NabVarContext.Provider
      value={{
        userData: state.userData,
        navigation: state.navigation,
        userNavigation: userNavigation,
        navigateToItem,
      }}
    >
      {props.children}
    </NabVarContext.Provider>
  )
}

export default NavBarState
