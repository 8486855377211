import React from 'react'
import MainTemplate from '../templates/MainTemplate'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../components/shared/Loading'
import NavBarState from '../context/navbar/NavBarState'
import UserState from '../context/user/UserState'
import DashboardInfo from '../components/dashboard/DashboardInfo'

const DashboardPage = () => {
  return (
    <UserState>
      <NavBarState>
        <MainTemplate element='Dashboard'>
          <DashboardInfo />
        </MainTemplate>
      </NavBarState>
    </UserState>
  )
}

export default withAuthenticationRequired(DashboardPage, {
  onRedirecting: () => <Loading />,
})
