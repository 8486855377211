import React, { useContext, useEffect, useState } from 'react'
import UserStats from './user/UserStats'
import Stats from './admin/Stats'
import UserContext from '../../context/user/UserContext'

const DashboardInfo = () => {
  const [userRole, setUserRole] = useState('eyonys')
  const userState = useContext(UserContext)
  const { role } = userState

  useEffect(() => {
    setUserRole(role)
  }, [userState])

  return <> {userRole === 'Administrator' ? <Stats /> : <UserStats />}</>
}

export default DashboardInfo
