import React from 'react'
import MainTemplate from '../templates/MainTemplate'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../components/shared/Loading'
import NavBarState from '../context/navbar/NavBarState'
import UserState from '../context/user/UserState'
import AppsInfo from '../components/apps/AppsInfo'

const AppsPage = () => {
  return (
    <UserState>
      <NavBarState>
        <MainTemplate element='Aplicaciones'>
          <main>
            <div className='max-w-7xl mx-auto py-4 sm:px-6 lg:px-8'>
              {/* Replace with your content */}
              <div className='px-4 sm:px-0'>
                <AppsInfo />
              </div>
              {/* /End replace */}
            </div>
          </main>
        </MainTemplate>
      </NavBarState>
    </UserState>
  )
}

export default withAuthenticationRequired(AppsPage, {
  onRedirecting: () => <Loading />,
})
