import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../components/shared/Loading'

const NotFoundPage = () => {
  return <div>NotFoundPage</div>
}

export default withAuthenticationRequired(NotFoundPage, {
  onRedirecting: () => <Loading />,
})
