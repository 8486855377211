import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../context/user/UserContext'
import LogOut from '../../pages/log-out'
import Loading from '../shared/Loading'
import NodeCard from './NodeCard'

const NodeInfo = () => {
  const [loaded, setLoaded] = useState(false)
  //User Context
  const [userRole, setUserRole] = useState('')
  const userState = useContext(UserContext)
  const { role } = userState

  useEffect(() => {
    if (role === 'Administrator' || role === 'User') {
      setUserRole(role)
      setLoaded(true)
    }
  }, [userState])

  return (
    <section className='py-8 bg-blueGray-50'>
      <div className='container px-4 mx-auto'>
        {loaded === false ? (
          <Loading />
        ) : userRole === 'Administrator' ? (
          <>
            <NodeCard />
          </>
        ) : (
          <LogOut />
        )}
      </div>
    </section>
  )
}

export default NodeInfo
