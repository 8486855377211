import React from 'react'
import MainTemplate from '../templates/MainTemplate'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../components/shared/Loading'
import NavBarState from '../context/navbar/NavBarState'
import UserState from '../context/user/UserState'
import NodeInfo from '../components/node/NodeInfo'

const NodePage = () => {
  return (
    <UserState>
      <NavBarState>
        <MainTemplate element='Nodo'>
          <NodeInfo />
        </MainTemplate>
      </NavBarState>
    </UserState>
  )
}

export default withAuthenticationRequired(NodePage, {
  onRedirecting: () => <Loading />,
})
