import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Login from '../auth/Login'
import { Navigate } from 'react-router-dom'
import Loading from '../components/shared/Loading'

const Home = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  return isLoading ? (
    <Loading />
  ) : isAuthenticated ? (
    <>
      <Navigate to='/dashboard' />
    </>
  ) : (
    <Login />
  )
}

export default Home
