import { SET_USER_ROLE } from '../../types/user'

const UserReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload,
      }
    default:
      return state
  }
}

export default UserReducer
