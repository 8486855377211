import React, { useContext, useEffect, useState } from 'react'
import AdminApps from './admin/AdminApps'
import UserApps from './user/UserApps'
import UserContext from '../../context/user/UserContext'
import Loading from '../shared/Loading'

const AppsInfo = () => {
  const [userRole, setUserRole] = useState('')
  const userState = useContext(UserContext)
  const { role } = userState

  useEffect(() => {
    setUserRole(role)
  }, [userState])

  return (
    <>
      {userRole === '' ? (
        <Loading />
      ) : userRole === 'Administrator' ? (
        <AdminApps />
      ) : (
        <UserApps />
      )}
    </>
  )
}

export default AppsInfo
