import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const LogOut = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: process.env.BASE_URL })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default LogOut
