import React, { useContext, useEffect, useState } from 'react'
import Footer from '../components/shared/Footer'
import NavBar from '../components/shared/NavBar'
import NabVarContext from '../context/navbar/NabVarContext'
import { useIdleTimer } from 'react-idle-timer'
import LogOut from '../pages/log-out'

const MainTemplate = ({ children, element }) => {
  //Is Loading Iddle
  const [loaded, setLoaded] = useState(false)
  //Iddle time-out
  const timeout = 900000
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => setIsIdle(true)

  const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  })

  useEffect(() => {
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000)

    setLoaded(true)
  }, [])

  //Read information from NabVarContext
  const navBarContext = useContext(NabVarContext)
  const { navigateToItem, navigation } = navBarContext

  useEffect(() => {
    navigateToItem(element)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className='min-h-full'>
        <NavBar />
        <header className='bg-white shadow'>
          <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8'>
            <h1 className='text-3xl font-bold text-gray-900'>
              {navigation.map((nav) => (nav.current ? nav.name : null))}
            </h1>
            {loaded ? isIdle ? <LogOut /> : null : null}
          </div>
        </header>
        <main>
          <div className='max-w-10xl mx-auto py-4 sm:px-6 lg:px-8'>
            {/* Replace with your content */}
            <div className='px-4 sm:px-0'>{children}</div>
            {/* /End replace */}
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default MainTemplate
