import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { LockClosedIcon } from '@heroicons/react/solid'

const Login = () => {
  const { loginWithRedirect } = useAuth0()
  return (
    <>
      <div className='flex justify-center items-center h-screen bg-gradient-to-br from-sky-50 to-gray-200'>
        <div className='relative container m-auto px-6 text-gray-500 md:px-12 xl:px-40'>
          <div className='m-auto md:w-8/12 lg:w-6/12 xl:w-6/12'>
            <div className='rounded-xl bg-white shadow-xl'>
              <div className='p-6 sm:p-16'>
                <div className='space-y-4'>
                  <h1 className='text-2xl text-center font-extrabold text-gray-900 sm:text-3xl md:text-3xl'>
                    Debes autenticarte
                    <br /> para acceder al
                    <br />
                    <span className='block text-indigo-600 xl:inline'>
                      Panel Administrativo
                    </span>
                  </h1>
                </div>
                <div className='mt-10 grid space-y-4'>
                  <button
                    type='submit'
                    onClick={() => loginWithRedirect()}
                    className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    <span className='absolute left-0 inset-y-0 flex items-center pl-3'>
                      <LockClosedIcon
                        className='h-5 w-5 text-indigo-500 group-hover:text-indigo-400'
                        aria-hidden='true'
                      />
                    </span>
                    Acceder
                  </button>
                </div>
                <div className='mt-10 space-y-4 text-gray-600 text-center sm:-mb-8'>
                  <p className='text-xs'>
                    By proceeding, you agree to our{' '}
                    <Link to='#' className='underline'>
                      Terms of Use
                    </Link>{' '}
                    and confirm you have read our{' '}
                    <Link to='#' className='underline'>
                      Privacy and Cookie Statement
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
